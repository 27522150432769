import { Link } from "react-router-dom"
import Screen from "../Components/Screen"
import TextImage from "../Components/TextImage"
import sample from "../Images/sample.png"
import bands from "../Images/BANDS.png"
import Carousel from "react-multi-carousel";
import el from "../Images/Ellipse.png"
const SampleTracks = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1550 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        laptop: {
            breakpoint: { max: 1550, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    return (
        <Screen>
            <TextImage image={sample} title={<h2 className="title">SAMPLE TRACKS</h2>}>
                <p>At Vault Studios, we understand the importance of making informed decisions when it comes to your music production journey. That's why we proudly offer a selection of sample tracks that showcase the exceptional quality and diverse capabilities of our studio. Our sample tracks serve as a glimpse into the world of possibilities awaiting you at Vault Studios.</p>
                <p>Each sample track is carefully chosen to represent different genres, styles, and production techniques, giving you a taste of the versatility and expertise we bring to every project.</p>
                <Link to={"/contact-us"}>Get in touch with us</Link>
            </TextImage>
            <div className="sample-bg">
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={false}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    <div className="sampleItem">
                        <img src={el} className="sampleItem__image"/>
                        <p className="sampleItem__title">Example Track Name 1</p>
                        <p className="sampleItem__tit">Example Artist Name</p>
                    </div>
                    <div className="sampleItem">
                        <img src={el} className="sampleItem__image"/>
                        <p className="sampleItem__title">Example Track Name 2</p>
                        <p className="sampleItem__tit">Example Artist Name</p>
                    </div>
                    <div className="sampleItem">
                        <img src={el} className="sampleItem__image"/>
                        <p className="sampleItem__title">Example Track Name 3</p>
                        <p className="sampleItem__tit">Example Artist Name</p>
                    </div>
                    <div className="sampleItem">
                        <img src={el} className="sampleItem__image"/>
                        <p className="sampleItem__title">Example Track Name 4</p>
                        <p className="sampleItem__tit">Example Artist Name</p>
                    </div>
                    <div className="sampleItem">
                        <img src={el} className="sampleItem__image"/>
                        <p className="sampleItem__title">Example Track Name 5</p>
                        <p className="sampleItem__tit">Example Artist Name</p>
                    </div>
                    <div className="sampleItem">
                        <img src={el} className="sampleItem__image"/>
                        <p className="sampleItem__title">Example Track Name 6</p>
                        <p className="sampleItem__tit">Example Artist Name</p>
                    </div>
                    <div className="sampleItem">
                        <img src={el} className="sampleItem__image"/>
                        <p className="sampleItem__title">Example Track Name 7</p>
                        <p className="sampleItem__tit">Example Artist Name</p>
                    </div>
                    <div className="sampleItem">
                        <img src={el} className="sampleItem__image"/>
                        <p className="sampleItem__title">Example Track Name 8</p>
                        <p className="sampleItem__tit">Example Artist Name</p>
                    </div>
                </Carousel>
            </div>
            <TextImage flipped dark image={bands} title={<h2 className="title">WHO WE'VE WORKED WITH</h2>}>
                <p>Jorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti </p>
                <p>sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. </p>
            </TextImage>
        </Screen>
    )
}

export default SampleTracks