import Screen from "../Components/Screen"

const TermsConditions = () => {
    const mobile = window.matchMedia("(max-width: 500px)").matches

    return (
        <Screen>
            <div className={`bg-dark ${mobile && mobile ? "p-1" : "p-5"}`}>
            <h1 className="gold-text">Terms & Conditions</h1>

            <h3 className="gold-text">1. Studio Booking and Cancellation:</h3>
            <ul>
                <li>Studio sessions must be booked in advance through our online booking system or by contacting one of our studio managers.</li>
                <li>A deposit is required to secure a booking and will be deducted from the total session cost.</li>
                <li>Cancellations must be made at least 48 hours prior to the scheduled session to receive a full deposit refund. Cancellations made within 48 hours will result in a forfeiture of the deposit.</li>
                <li>Vault Studios reserves the right to cancel or reschedule sessions due to unforeseen circumstances. In such cases, clients will be offered an alternative booking or a full refund.</li>
            </ul>

            <h3 className="gold-text">2. Payment:</h3>
            <ul>
                <li>Payment for studio sessions must be made in full before any content is released to the client.</li>
                <li>We accept either cash payments or bank transfer.</li>
            </ul>

            <h3 className="gold-text">3. Studio Rules:</h3>
            <ul>
                <li>Clients are responsible for the behavior of their guests during studio sessions. Any damage to equipment or facilities caused by clients or their guests will be the financial responsibility of the client.</li>
                <li>Smoking and illegal substances are strictly prohibited within the studio premises.</li>
                <li>Clients are expected to maintain a respectful and professional environment within the studio at all times.</li>
            </ul>

            <h3 className="gold-text">4. Equipment and Facilities:</h3>
            <ul>
                <li>Clients are responsible for treating all equipment and facilities with care and respect.</li>
                <li>Any technical issues or equipment malfunctions should be reported immediately to the studio engineer or manager.</li>
                <li>Clients are not allowed to modify or tamper with studio equipment without prior authorisation.</li>
            </ul>

            <h3 className="gold-text">5. Intellectual Property and Copyright:</h3>
            <ul>
                <li>Clients retain ownership of their original musical compositions and recordings created during studio sessions.</li>
                <li>Vault Studios must be named as the producer for any published music.</li>
                <li>Vault Studios retains the right to use recorded material for promotional purposes, with proper attribution to the artist(s).</li>
                <li>It is the responsibility of the client to ensure that all necessary permissions and licenses for samples, cover songs, or any copyrighted material are obtained before recording.</li>
            </ul>

            <h3 className="gold-text">6. Confidentiality:</h3>
            <ul>
                <li>Vault Studios respects the confidentiality of all client projects and will not share or distribute any client recordings or information without explicit consent.</li>
            </ul>

            <h3 className="gold-text">7. Liability:</h3>
            <ul>
                <li>Vault Studios is not liable for any loss, damage, or theft of personal belongings brought into the studio premises by clients or their guests.</li>
                <li>Clients are responsible for their own safety and well-being while within the studio premises.</li>
            </ul>

            <h3 className="gold-text">8. Amendments:</h3>
            <ul>
                <li>These terms and conditions are subject to change without prior notice. Clients will be notified of any amendments through email or direct communication.</li>
                <li>By booking a session at Vault Studios, you acknowledge and agree to abide by these terms and conditions. If you have any questions or require clarification, please contact a studio manager before proceeding.</li>
            </ul>
            </div>
        </Screen>
    )
}

export default TermsConditions