import { ReactNode, useState } from "react"
import { Link } from "react-router-dom"
import logo from "../Images/LOGO_v4_removebg.png"
import { FaSquareFacebook, FaSquareInstagram, FaBars, FaXmark } from "react-icons/fa6";
import { Offcanvas } from "react-bootstrap";
import { useLocation } from 'react-router-dom'
interface Props {
    children: ReactNode
    homeItem?: ReactNode
}

const Screen = ({ children, homeItem }: Props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const tablet = window.matchMedia("(max-width: 1024px)").matches
    const location = useLocation();
    return (
        <div className="screen">
            {homeItem}
            <div className="screen-nav">
                <img src={logo} className="screen-nav__logo" />
                {!tablet &&
                    <div className="screen-nav-links">
                        <Link className={`screen-nav-links__link ${location.pathname === "/" && "screen-nav-links__link--active"}`} to={"/"}>Home</Link>
                        <Link className={`screen-nav-links__link ${location.pathname === "/about-us" && "screen-nav-links__link--active"}`} to={"/about-us"}>About Us</Link>
                        <Link className={`screen-nav-links__link ${location.pathname === "/services" && "screen-nav-links__link--active"}`} to={"/services"}>Services</Link>
                        {/* <Link className={`screen-nav-links__link ${location.pathname === "/sample-tracks" && "screen-nav-links__link--active"}`} to={"/sample-tracks"}>Sample Tracks</Link> */}
                        {/* <Link className={`screen-nav-links__link ${location.pathname === "/gallery" && "screen-nav-links__link--active"}`} to={"/gallery"}>Gallery</Link> */}
                        <Link className={`screen-nav-links__link mr-3 ${location.pathname === "/contact-us" && "screen-nav-links__link--active"}`} to={"/contact-us"}>Contact Us</Link>
                        <a href="https://www.facebook.com/profile.php?id=100094825867613"><FaSquareFacebook className="mr-1" color="white" size={25} /></a>
                        <a href="https://www.instagram.com/vault_studios_/"><FaSquareInstagram color="white" size={25} /></a>
                        
                    </div>
                }
                {tablet &&
                    <FaBars onClick={handleShow} color="#F9C340" size={40} />
                }
                <Offcanvas placement="end" show={show} onHide={handleClose}>
                    <Offcanvas.Header>
                        <Offcanvas.Title className="w-100">
                            <div className="w-100 d-flex flex-row justify-content-between align-items-center">
                            <h2 className="title">Navigation</h2>
                            <FaXmark onClick={handleClose} color="#F9C340" size={40}/>
                            </div>
                            </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="d-flex flex-column">
                        <Link className={`screen-nav-links__link ${location.pathname === "/" && "screen-nav-links__link--active"}`} to={"/"}>Home</Link>
                        <Link className={`screen-nav-links__link ${location.pathname === "/about-us" && "screen-nav-links__link--active"}`} to={"/about-us"}>About Us</Link>
                        <Link className={`screen-nav-links__link ${location.pathname === "/services" && "screen-nav-links__link--active"}`} to={"/services"}>Services</Link>
                        {/* <Link className={`screen-nav-links__link ${location.pathname === "/sample-tracks" && "screen-nav-links__link--active"}`} to={"/sample-tracks"}>Sample Tracks</Link> */}
                        {/* <Link className={`screen-nav-links__link ${location.pathname === "/gallery" && "screen-nav-links__link--active"}`} to={"/gallery"}>Gallery</Link> */}
                        <Link className={`screen-nav-links__link mr-3 ${location.pathname === "/contact-us" && "screen-nav-links__link--active"}`} to={"/contact-us"}>Contact Us</Link>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
            <div className="screen-wrap">
                {children}
            </div>
            <div className="screen-footer">
                <div className="d-flex justify-content-center align-items-center">
                    <img src={logo} className="screen-footer__logo" />
                </div>
                <div>
                    <h5 className="screen-footer__title">Navigation</h5>
                    <div className="d-flex flex-row">
                        <div className="d-flex flex-column mr-2">
                            <Link className="screen-footer__link screen-footer__font" to={"/"}>Home</Link>
                            <Link className="screen-footer__link screen-footer__font" to={"/about-us"}>About Us</Link>
                            <Link className="screen-footer__link screen-footer__font" to={"/services"}>Services</Link>
                        </div>
                        <div className="d-flex flex-column">
                            {/* <Link className="screen-footer__link screen-footer__font" to={"/sample-tracks"}>Sample Tracks</Link> */}
                            {/* <Link className="screen-footer__link screen-footer__font" to={"/gallery"}>Gallery</Link> */}
                            <Link className="screen-footer__link screen-footer__font" to={"/contact-us"}>Contact Us</Link>
                            <Link className="screen-footer__link screen-footer__font" to={"/terms-and-conditions"}>Terms & Conditions</Link>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <h5 className="screen-footer__title">Contact Information</h5>
                    <a className="screen-footer__font" href="mailto:admin@vaultstudios.co.uk">Admin@VaultStudios.co.uk</a>
                    <div className="d-flex flex-row">
                    <a href="https://www.facebook.com/profile.php?id=100094825867613"><FaSquareFacebook className="mr-1" color="white" size={25} /></a>
                        <a href="https://www.instagram.com/vault_studios_/"><FaSquareInstagram color="white" size={25} /></a>
                        
                    </div>
                </div>
                <div>
                    <h5 className="screen-footer__title">Find Us</h5>
                    <p className="m-0 screen-footer__font">Vault Studios</p>
                    <p className="m-0 screen-footer__font">FORGE</p>
                    <p className="m-0 screen-footer__font">148 Effingham Road</p>
                    <p className="m-0 screen-footer__font">Sheffield</p>
                    <p className="m-0 screen-footer__font">South Yorkshire</p>
                    <p className="m-0 screen-footer__font">S4 7ZB</p>
                </div>
            </div>
        </div>
    )
}

export default Screen