import Carousel from 'react-bootstrap/Carousel';
import bg from "../Images/carousel.png"
import { ReactNode } from 'react';

interface Props {
    items: {
        title: string;
        description: ReactNode;
        img: string
    }[]
}
const mobile = window.matchMedia("(max-width: 500px)").matches

const VSCarousel = ({ items }: Props) => {
    return (
        <Carousel>
            {items.map((item) => {
                return (
                    <Carousel.Item className='vsCarousel-item'>
                        <div
                        style={{backgroundImage: `url(${item.img})`}}
                            className="d-block w-100 vsCarousel-item__img"
                        >
                            </div>
                            <div className='vsCarousel-item-content'>
                                <div className='vsCarousel-item-content-text'>
                                    <h2 className='title'>{item.title}</h2>
                                    {item.description}
                                </div>
                            </div>
                        
                    </Carousel.Item>
                )
            })}
        </Carousel>
    )
}

export default VSCarousel