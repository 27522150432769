import { Children, ReactNode } from "react";

interface Props {
    flipped?: boolean;
    dark?: boolean;
    image: string;
    children: ReactNode;
    title: ReactNode
}

const TextImage = ({ flipped = false, dark = false, image, children, title }: Props) => {
    return (
        <div className="textImage">
            {flipped ?
                <>
                    <div className={`textImage-text ${dark && "textImage-text--dark"}`}>
                        {title}
                        {children}
                    </div>
                    <div className="textImage-image">
                        <div style={{ backgroundImage: `url(${image})` }} className="textImage-image__bg"></div>
                    </div>
                </>
                :
                <>
                    <div className="textImage-image">
                        <div style={{ backgroundImage: `url(${image})` }} className="textImage-image__bg"></div>
                    </div>
                    <div className={`textImage-text ${dark && "textImage-text--dark"}`}>
                        {title}
                        {children}
                    </div>
                </>
            }
        </div>
    )
}

export default TextImage