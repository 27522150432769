import { Button, Form } from "react-bootstrap"
import Screen from "../Components/Screen"
import TextImage from "../Components/TextImage"
import map from "../Images/map.png"
import { useState } from "react"
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from "react-toastify";
const Contact = () => {
    const [name, setName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [message, setMessage] = useState<string>("")
    const mobile = window.matchMedia("(max-width: 500px)").matches
    const sendMail = () => {
        emailjs
            .send('service_ruun834', 'template_fu0ysr6', { name: name, email: email, message: message }, {
                publicKey: '7xTS7kLMplcKrYIri',
            })
            .then(
                () => {
                    setName("")
                    setEmail("")
                    setMessage("")
                    toast.success('Contact form submitted!', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };
    return(
        <Screen>
             <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className={`d-flex w-100 ${!mobile && "p-5"} ${mobile && "flex-column p-1"}`}>
                <div className={`w-50 p-3 ${mobile && "w-100 p-1"}`}>
                <h2 className="title">GET IN TOUCH WITH US</h2>
                <p>We are thrilled to connect with you and discuss how we can bring your musical aspirations to life. Below are the various ways you can get in touch with our dedicated team. Please either use the contact form or the contact details below.</p>
                <p>Whether you're a seasoned musician or a newcomer to the music scene, we're here to support and guide you throughout your music production journey. Don't hesitate to reach out.</p>
                <div className="d-flex flex-column">
                <a href="mailto:admin@vaultstudios.co.uk">Admin@VaultStudios.co.uk</a>
                </div>
                </div>
                <div className={`w-50 p-3 ${mobile && "w-100 p-1"}`}>
                <Form.Group className="mb-3">
                    <Form.Label>Full name:</Form.Label>
                    <Form.Control value={name} onChange={(e) => setName(e.currentTarget.value)} placeholder="Enter full name..." />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Email:</Form.Label>
                    <Form.Control value={email} onChange={(e) => setEmail(e.currentTarget.value)} type="email" placeholder="Enter email address..." />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Message:</Form.Label>
                    <Form.Control value={message} onChange={(e) => setMessage(e.currentTarget.value)} placeholder="Enter message..." as="textarea" rows={3} />
                </Form.Group>
                <div className="d-flex justify-content-end">
                    <Button onClick={() => sendMail()} className="VSButton VSButton--gold">ENQUIRE</Button>
                </div>
                </div>
            </div>
            <TextImage
            image={map}
            title={<h2 className="title">WHERE TO FIND US</h2>}
            dark
            >
                <p>Vault Studios is based within FORGE Warehouse in Sheffield. FORGE is the brand-new sister venue of the reputable Southbank Warehouse, located just 100 yards down the road. Both venues put on regular events such as DJs, weddings, craft fairs, markets and of course, live bands and artists.</p>
                <p>Not only are we thankful to be associated with such highly regarded venues, but it means that Vault Studios is surrounded other creative people and is a hotspot for inspiration; getting the best results out of our small team that work within the studio as well as our clients.</p>
                <p className="m-0 font-bold">Vault Studios</p>
                    <p className="m-0">FORGE</p>
                    <p className="m-0">148 Effingham Road</p>
                    <p className="m-0">Sheffield</p>
                    <p className="m-0">South Yorkshire</p>
                    <p className="m-0">S4 7ZB</p>
            </TextImage>
        </Screen>
    )
}

export default Contact